var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{ref:"class_add"},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-card-text',[_c('span',[_vm._v("Select Class ")])]),_c('validation-provider',{attrs:{"name":"classes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.classes},model:{value:(_vm.class_id),callback:function ($$v) {_vm.class_id=$$v},expression:"class_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-card-text',[_c('span',[_vm._v("Select wings ")])]),_c('validation-provider',{attrs:{"name":"wings","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.wings},model:{value:(_vm.wing_id),callback:function ($$v) {_vm.wing_id=$$v},expression:"wing_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-card-text',[_c('span',[_vm._v("Select sections ")])]),_c('validation-provider',{attrs:{"name":"sections","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","options":_vm.sections},model:{value:(_vm.section_ids),callback:function ($$v) {_vm.section_ids=$$v},expression:"section_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-card-text',[_c('span',[_vm._v("Select academic year ")])]),_c('validation-provider',{attrs:{"name":"academic year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"year","options":_vm.academic_years},model:{value:(_vm.academic_year_id),callback:function ($$v) {_vm.academic_year_id=$$v},expression:"academic_year_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }